jQuery(function($) {
  $('.slider').each(function() {
    var slider = $(this)
    var config = {
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      pageDots: false,
      contain: false
    }

    slider.flickity(config)
  })

  $('.carousel-main').flickity();
  $('.carousel-nav').flickity({
    asNavFor: '.carousel-main',
    wrapAround: true,
    imagesLoaded: true,
    pageDots: false,
    cellAlign: 'left',
    contain: false,
    arrowShape: { 
      x0: 10,
      x1: 60, y1: 50,
      x2: 60, y2: 40,
      x3: 20
    }
  });
});