jQuery(document).ready(function($) {
  require('./components/mobile-header.js');
  require('./components/slider.js');

  //Scroll Active
  jQuery(window).on('scroll', function() {
    if (1 < window.pageYOffset) {
      jQuery('header').addClass('scroll-active');
    } else {
      jQuery('header').removeClass('scroll-active');
    }
  });

  jQuery(window).on('load', function() {
    if (1 < window.pageYOffset) {
      jQuery('header').addClass('scroll-active');
    } else {
      jQuery('header').removeClass('scroll-active');
    }
  });
});